import { Button, Grid } from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react'
import { useForm } from 'react-hook-form';
import InputText from '../../../../components/Inputs/RHK/InputText';
import InputDate from '../../../../components/Inputs/RHK/InputDate';
// import InputSelect from '../../../components/Inputs/RHK/InputSelect';
// import InputCheckbox from '../../../components/Inputs/RHK/InputCheckbox/InputCheckbox';
// import { dateYesterday } from '../../../../Utils/Date'
import { updateTableFilters } from '../../../../reducers/tableReducer';
import InputSelect from '../../../../components/Inputs/RHK/InputSelect';
import { tfgRequestStatusOptions } from '../../TFG.utils';
import {setApplicationLoading} from "../../../../components/ApplicationLoading";
import {getTFGInstructors, getTFGTitulations} from "../../../../services/TFG";
import {useNotification} from "../../../../Utils/hooks";

const TFGRequestsFilters = ({ dispatch, onDownloadCSV }) => {
  const { handleSubmit, control } = useForm();
  const [degrees, setDegrees] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const notification = useNotification();

  const retrieveDegrees = useCallback(async () => {
    try {
      setApplicationLoading(true);
      const result = await getTFGTitulations().then(
          res => res.map(item => ({ value: item.externalId, label: item.name }))
      );
      if (!result) throw new Error();
      const ordered = result.sort((first, second) => first.label.localeCompare(second.label));
      setDegrees(ordered);
    } catch {
      notification('error', 'Ha habido un error al obtener las titulaciones');
    } finally {
      setApplicationLoading(false);
    }
  }, [notification]);

  const retrieveInstructors = useCallback(async () => {
    try {
      setApplicationLoading(true);
      const result = await getTFGInstructors().then(
          res => res.map(item => ({ value: item.externalId, label: item.name }))
      );
      if (!result) throw new Error();
      const ordered = result.sort((first, second) => first.label.localeCompare(second.label));
      setInstructors(ordered);
    } catch {
      notification('error', 'Ha habido un error al obtener los coordinadores');
    } finally {
      setApplicationLoading(false);
    }
  }, [notification]);

  useEffect(() => {
    retrieveDegrees();
    retrieveInstructors();
  }, [retrieveInstructors]);

  const onSubmit = data => {
    dispatch(updateTableFilters(data));
  }

  const onDownload = () => {
    onDownloadCSV();
  }

  return (
    <div className="requests-filters">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Filtros</h3>
        </Grid>
        <Grid item md={4} xs={12}>
          <InputSelect
            name="degreeIds"
            label="ID de titulación"
            control={control}
            options={degrees}
            multiple={true}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputSelect
            name="instructorId"
            label="ID del instructor"
            control={control}
            options={instructors}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputText
            name="studentId"
            label="ID del estudiante"
            control={control}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputSelect
            name="status"
            label="Estado de la solicitud"
            options={tfgRequestStatusOptions}
            control={control}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputDate
            name="createdFrom"
            label="Fecha de creación (posterior a)"
            control={control}
            // defaultValue={dateYesterday()}
            defaultValue={null}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputDate
            name="createdTo"
            label="Fecha de creación (anterior a)"
            control={control}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="filters--actions">
            <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
            >
              Aplicar filtros
            </Button>
          </div>
          <div className="filters--actions">
            <Button
                onClick={handleSubmit(onDownload)}
                variant="contained"
                color="primary"
            >
              Descargar CSV
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default TFGRequestsFilters;