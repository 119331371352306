import Http from '../../Utils/Http';
import { generateFileFormData, generateRandomId, localDevCatch } from '../../Utils/utils';
import {
  mockedTFGInstructors, mockedTFGManagerInfo, mockedTFGManagers,
  mockedTFGRequests, mockedTFGSubjects, mockedTFGTerms, mockedTFGTitulations,
  mockedTFGYears,
  mockedTFGCourt,
  mockedTFGDefenseStudentCourts,
  mockedTFGRequestsWithPrefferences,
  mockedTFGPriorityGrades,
} from './TFGService.mock';

// const path = isDev ? '' : '/tfg-api';

// export const getTFGTitulations = async (all, userId) => {
//   const result = await Http.get(`/tfg-api/degrees`, { params: { all, userId }})
//     .then(res => res.data)
//     .catch((err) => localDevCatch(err, mockedTFGTitulations));
//   return result;
// };

// export const createTFGRequest = async (degreeId, instanceId, areaId, instructorId, userSelected) => {
//   const result = await Http.post(`/tfg-api/requests`, { userId: userSelected || null, degreeId, instanceId, areaId, instructorId })
//     .then(res => res.data)
//     .catch((err) => localDevCatch(err, true));
//   return result;
// }

export const createTFGRequest = async (degreeId, termId, areaId, instructorId, userSelected, provisionalTitle, priority) => {
  const result = await Http.post(
    `/tfg-api/requests`,
    { userId: userSelected || null, degreeId, termId, areaId, instructorId, provisionalTitle, priority }
  )
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
};

export const updateTFGRequest = async data => {
  const result = await Http.patch(`/tfg-api/requests/${data.id}`, data)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, data));
  return result;
};

export const getTFGRequests = async (manualFilters, pagination, sortOrder, filters) => {
  const { page, size } = pagination;
  let params = { page, size };
  if (manualFilters) params = { ...params, ...manualFilters };
  if (sortOrder?.name) params = { ...params, order: sortOrder.name, direction: sortOrder.direction.toUpperCase() }
  if (filters && Object.keys(filters).length) params = { ...params, ...filters };

  const result = await Http.get(`/tfg-api/requests`, { params })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { content: mockedTFGRequests, totalElements: mockedTFGRequests.length }));
  return result;
};

export const createTFGSubject = async body => {
  const result = await Http.post(`/tfg-api/areas`, body)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, {
      ...body,
      id: 1464,
      assignedManagers: body.assignedManagerIds.map(manager => ({ externalId: manager, name: manager }))
    }));
  return result;
}

export const updateTFGSubject = async body => {
  const result = await Http.put(`/tfg-api/areas/${body.id}`, body)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, body));
  return result;
}

export const deleteTFGSubject = async id => {
  const result = await Http.delete(`/tfg-api/areas/${id}`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const acceptTFGRequest = async id => {
  const result = await Http.post(`/tfg-api/requests/${id}/accept`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const rejectTFGRequest = async (id, reason) => {
  const result = await Http.post(`/tfg-api/requests/${id}/reject`, { reason })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const cancelTFGRequest = async id => {
  const result = await Http.post(`/tfg-api/requests/${id}/cancel`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const uploadTFGFile = async (id, file) => {
  const formData = generateFileFormData(file, 'files');

  const result = await Http.post(`/tfg-api/requests/${id}/file`, formData)
    .then(res => res.data)
    .catch(err => localDevCatch(err, true))
  return result;
}

export const downloadTFGPDF = async instanceId => {
  const result = await Http.get(`/tfg-api/requests/${instanceId}/pdf`, { responseType: 'blob' })
    .then(res => {
      const { data, headers } = res;
      const name = headers['content-disposition']
        .split('filename="')[1]
        .split('"')[0];
      return ({ file: data, name })
    })
    .catch(err => localDevCatch(err, new Blob(['<span>Test</span>'])));
  return result
}

export const downloadRequestsCSV = async filters => {
  const result = await Http.get(`/tfg-api/requests/csv`, { params: filters, responseType: 'blob' })
      .then(res => {
        const { data, headers } = res;
        const name = headers['content-disposition']
            .split('filename="')[1]
            .split('"')[0];
        return ({ file: data, name })
      })
      .catch(err => localDevCatch(err, new Blob(['<span>Test</span>'])));
  return result
}

// export const getTFGTerms = async () => {
//   const result = await Http.get(`/tfg-api/terms`)
//     .then(res => res.data)
//     .catch((err) => localDevCatch(err, mockedTFGTerms));
//   return result;
// };

// export const getTFGDegreeCoordinators = async id => {
//   const result = await Http.get(`/tfg-api/degrees/${id}/coordinators`)
//     .then(res => res.data)
//     .catch((err) => localDevCatch(err, mockedTFGManagers));
//   return result;
// };

// export const getTFGTitulationAreas = async (id, all) => {
//   const config = all ? { params: { all: true } } : undefined;
//   const result = await Http.get(`/tfg-api/titulations/${id}/areas`, config)
//     .then(res => res.data)
//     .catch((err) => localDevCatch(err, mockedTFGSubjects));
//   return result;
// };

// export const getTFGManagers = async () => {
//   const result = await Http.get(`/tfg-api/managers`)
//     .then(res => res.data)
//     .catch((err) => localDevCatch(err, mockedTFGManagers));
//   return result;
// };

// export const getTFGManagerInfo = async id => {
//   const result = await Http.get(`/tfg-api/managers/${id}`)
//     .then(res => res.data)
//     .catch((err) => localDevCatch(err, mockedTFGManagerInfo));
//   return result;
// };

// export const updateTFGManagerLimit = async (id, limit) => {
//   const result = await Http.post(`/tfg-api/managers/${id}/limit`, { limit })
//     .then(res => res.data)
//     .catch((err) => localDevCatch(err, true));
//   return result;
// };

/**
 * ==================
 *   NEW TFG MODULE
 * ==================
 **/

export const getTFGAcademicYears = async () => {
  const result = await Http.get(`/tfg-api/academicyears`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGYears));
  return result;
};

export const getTFGTerms = async (type, degree, academicYear, degreeId) => {
  let params = {};
  if (type) params.type = type;
  if (degree) params.degree = degree;
  if (academicYear) params.academicYear = academicYear;
  if (degreeId) params.degree = degreeId;

  const options = Object.keys(params).length ? { params } : undefined;

  const result = await Http.get(`/tfg-api/terms`, options)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGTerms));
  return result;
};

export const createTFGTerm = async term => {
  const result = await Http.post(`/tfg-api/terms`, term)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { id: 100, ...term }));
  return result;
};

export const updateTFGTerm = async term => {
  const result = await Http.put(`/tfg-api/terms`, term)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, term));
  return result;
};

export const deleteTFGTerm = async termId => {
  const result = await Http.delete(`/tfg-api/terms/${termId}`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
};

export const uploadTFGTermsCSV = async file => {
  const formData = generateFileFormData(file);
  const result = await Http.post(`/tfg-api/terms/csv`, formData)
    .then(res => res.data)
    .catch(err => localDevCatch(err, true))
  return result;
};

export const getTFGInstructors = async () => {
  const result = await Http.get(`/tfg-api/instructors`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGManagers));
  return result;
};

export const getTFGInstructorInfo = async (id) => {
  const result = await Http.get(`/tfg-api/instructors/${id}/allocation`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGManagerInfo));
  return result;
};

export const updateTFGInstructorLimit = async (id, limit) => {
  const result = await Http.post(`/tfg-api/instructors/${id}/limit`, { limit })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
};

export const createTFGInstructorAllocation = async (id, body) => {
  const result = await Http.post(`/tfg-api/instructors/${id}/allocation`, body)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, body));
  return result;
}

export const updateTFGInstructorAllocation = async (id, body) => {
  const result = await Http.patch(`/tfg-api/instructors/${id}/allocation`, body)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, body));
  return result;
}

export const uploadInstructorsCSV = async file => {
  const formData = generateFileFormData(file);
  const result = await Http.post(`/tfg-api/instructors/csv`, formData)
    .then(res => res.data)
    .catch(err => localDevCatch(err, { success: ['cesarelea', 'marcucav'], failed: ['profeucav'] }))
  return result;
}

export const getTFGInstructorAccess = async () => {
  const result = await Http.get(`/tfg-api/instructors/access`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { areas: true }));
  return result;
};

export const getTFGAreas = async (academicYearId, showHidden) => {
  const result = await Http.get(`/tfg-api/areas`, {
    params: {
      academicYearId,
      ...showHidden ? { all: true } : {}
    }
  })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGSubjects));
  return result;
};

export const uploadTFGAreasCSV = async file => {
  const formData = generateFileFormData(file);
  const result = await Http.post(`/tfg-api/areas/csv`, formData)
    .then(res => res.data)
    .catch(err => localDevCatch(err, true))
  return result;
};

export const downloadTFGAreasCSV = async () => {
  window.open(`/tfg-api/areas/csv/download`, "_blank");
};

export const getTFGDegreeAreas = async (degreeId, academicYearId, all) => {
  // const config = all ? { params: { all: true } } : undefined;
  const config = { params: { degreeId, academicYearId, all } };
  const result = await Http.get(`/tfg-api/degrees/${degreeId}/areas`, config)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGSubjects));
  return result;
};

export const getTFGTitulations = async (academicYearId, all, userId) => {
  const result = await Http.get(`/tfg-api/degrees`, { params: { academicYearId, all, userId }})
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGTitulations));
  return result;
};

export const getTFGAreaDegrees = async (academicYearId) => {
  const options = academicYearId ? { params: { academicYearId } } : undefined;
  const result = await Http.get(`/tfg-api/coordinators/degrees`, options)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGTitulations));
  return result;
};

export const getTFGCurrentRequests = async (degreeId, academicYearId, userId) => {
  const result = await Http.get(`/tfg-api/requests/current`, { params: { degreeId, academicYearId, userId }})
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGRequestsWithPrefferences));
  return result;
}

export const getTFGTitulationManagers = async ids => {
  const result = await Http.get(`/tfg-api/areas/instructors`, { params: { ids } })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGInstructors));
  return result;
};

/** Defensa de TFG */
export const getTFGCourts = async academicYearId => {
  const options = academicYearId ? { params: { academicYearId } } : undefined;
  const result = await Http.get(`/tfg-api/defenses/courts`, options)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGCourt));
  return result;
}

export const getTFGDefenseDirectors = async academicYearId => {
  const options = academicYearId ? { params: { academicYearId } } : undefined;
  const result = await Http.get(`/tfg-api/defenses/courts/directors`, options)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGInstructors));
  return result;
}

export const createTFGCourt = async court => {
    const result = await Http.post(`/tfg-api/defenses/courts`, court)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { id: 1337, timeSpans: [], ...court }));
  return result;
}

export const updateTFGCourt = async court => {
  const result = await Http.patch(`/tfg-api/defenses/courts/${court.id}/members`, court)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { timeSpans: [], ...court }));
  return result;
}

export const createTFGCourtTimespan = async (courtId, start) => {
  const result = await Http.put(`/tfg-api/defenses/courts/${courtId}/timespans`, undefined, { params: { start } })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { timeSpans: [{ id: generateRandomId(), start: "2024-09-18T10:00:00Z", reserved: false }] }));
  return result;
}

export const uploadTFGCourtTimestamps = async (courtId, file) => {
  const formData = generateFileFormData(file);
  const result = await Http.post(`/tfg-api/defenses/courts/${courtId}/timespans/csv`, formData)
    .then(res => res.data)
    .catch(err => localDevCatch(err, mockedTFGCourt[0].timeSpans))
  return result;
};

export const deleteTFGCourtTimestamp = (courtId, timestampId) => {
  const result = Http.delete(`/tfg-api/defenses/courts/${courtId}/timespans/${timestampId}`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const deleteTFGCourt = async id => {
  const result = await Http.delete(`/tfg-api/defenses/courts/${id}`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const getAvailableTFGCourts = async requestId => {
  const result = await Http.get(`/tfg-api/defenses/courts/requests/${requestId}`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGCourt));
  return result;
}

export const acceptTFGDefenseRequest = async (requestId, defenseId, location) => {
  const result = await Http.post(`/tfg-api/requests/${requestId}/defenses/${defenseId}/accept`, { location })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const rejectTFGDefenseRequest = async (requestId, defenseId, reason) => {
  const result = await Http.post(`/tfg-api/requests/${requestId}/defenses/${defenseId}/reject`, { reason })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const cancelTFGDefenseRequest = async (requestId, defenseId) => {
  const result = await Http.post(`/tfg-api/requests/${requestId}/defenses/${defenseId}/cancel`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const getTFGDefenseRequests = async (manualFilters, pagination, sortOrder, filters) => {
  const { page, size } = pagination;
  let params = { page, size };
  if (manualFilters) params = { ...params, ...manualFilters };
  if (sortOrder?.name) params = { ...params, order: sortOrder.name, direction: sortOrder.direction.toUpperCase() }
  if (filters && Object.keys(filters).length) params = { ...params, ...filters };

  const result = await Http.get(`/tfg-api/requests/defenses`, { params })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { content: mockedTFGRequests, totalElements: mockedTFGRequests.length }));
  return result;
};

export const createTFGDefenseRequest = async data => {
  const result = await Http.post(`/tfg-api/requests/${data.requestId}/defenses`, data)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { id: 144, ...data }));
  return result;
}

export const updateTFGDefenseRequest = async data => {
  const result = await Http.patch(`/tfg-api/requests/${data.requestId}/defenses/${data.id}`, data)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, data));
  return result;
};

export const getTFGDefenseStudentCourts = async (requestId, academicYearId) => {
  let params = {};
  if (academicYearId) params.academicYearId = academicYearId;
  const result = await Http.get(`/tfg-api/defenses/courts/requests/${requestId}`, { params })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTFGDefenseStudentCourts));
  return result;
}

export const downloadTFGDefenseFile = async (requestId, defenseRequestId) => {
  const result = await Http.get(`/tfg-api/requests/${requestId}/defenses/${defenseRequestId}/pdf`, { responseType: 'blob' })
    .then(res => {
      const { data, headers } = res;
      const name = headers['content-disposition']
        .split('filename="')[1]
        .split('"')[0];
      return ({ file: data, name })
    })
    .catch(err => localDevCatch(err, new Blob(['<span>Test</span>'])));
  return result
}

export const getTFGPriorityGrades = async pagination => {
  const { page, size } = pagination;
  let params = { page, size };

  const result = await Http.get(`/tfg-api/priority`, { params })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { content: mockedTFGPriorityGrades, totalElements: mockedTFGPriorityGrades.length }));
  return result;
}

export const uploadTFGPriorityGrades = async file => {
  const formData = generateFileFormData(file);
  const result = await Http.post(`/tfg-api/priority/csv`, formData)
    .then(res => res.data)
    .catch(err => localDevCatch(err, true))
  return result;
};