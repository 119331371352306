import React, { useState, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import { useConfirm } from 'material-ui-confirm';
import Stepper from "@material-ui/core/Stepper";
import { Button, StepLabel } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import Disclaimer from '../../../components/Disclaimer';
import InputText from '../../../components/Inputs/RHK/InputText';
import InputAutoCompleteValue from '../../../components/Inputs/RHK/InputAutoCompleteValue';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import StudentRecordUserSelectionContainer from '../../StudentRecord/CoordUserSelection/StudentRecordUserSelectionContainer';
import { createTFGRequest, getTFGCurrentRequests, getTFGDegreeAreas, getTFGTerms, getTFGTitulations } from '../../../services/TFG';
import { useNotification } from '../../../Utils/hooks';

const priorities = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

const TFGRequest = ({ onSuccess, extraordinary }) => {
  const { control, watch, setValue, reset } = useForm();
  const [userSelected, setUserSelected] = useState("")
  const [titulations, setTitulations] = useState([]);
  const [terms, setTerms] = useState([]);
  const [areas, setAreas] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [priority, setPriority] = useState(null);
  const { degreeId, termId, areaId, instructorId, provisionalTitle } = watch();
  const notification = useNotification();
  const confirm = useConfirm();

  const instructors = useMemo(() => {
    if (!areaId) return [];

    // get distinct instructors
    let managers = [];
    let map = new Map()
    for (const manager of areas.find(area => area.id === areaId)?.assignedManagers || []) {
      if(!map.has(manager.externalId)) {
        map.set(manager.externalId, manager)
        managers.push(manager)
      }
    }
  
    if (!managers || (managers && !managers.length)) {
      notification('error', 'No hay directores disponibles para este área temática');
      return [];
    }

    return managers.map(ins => ({ label: ins.name, value: ins.externalId }));
  }, [areaId, areas, notification]);

  const academicYear = useMemo(() => {
    if (!termId) return null;

    const term = terms.find(term => term.value === termId);

    return term.academicYear?.id;
  }, [termId, terms]);

  const steps = useMemo(() => {
    const StudentStep = (
      <>
        { userSelected && <p className="selected-user">Usuario seleccionado: {userSelected}</p> }
        <StudentRecordUserSelectionContainer
          userSelected={userSelected}
          setUserSelected={setUserSelected}
        />
      </>
    );
  
    const TitulationStep = (
      <InputAutoCompleteValue
        key="degreeId"
        name="degreeId"
        control={control}
        label="Titulación"
        options={titulations}
      />
    );
  
    const SubjectStep = (
      <InputAutoCompleteValue
        key="areaId"
        name="areaId"
        control={control}
        label="Área temática"
        options={areas}
      />
    );
  
    const TermStep = (
      <InputAutoCompleteValue
        key="termId"
        name="termId"
        control={control}
        label="Convocatoria"
        options={terms}
        // renderOption={renderInstanceOption}
      />
    );
  
    const InstructorStep = (
      <InputAutoCompleteValue
        name="instructorId"
        control={control}
        label="Director"
        options={instructors}
      />
    );

    const TitleStep = (
      <InputText
        key="provisionalTitle"
        name="provisionalTitle"
        label="Título"
        control={control}
        inputProps={{ placeholder: "Título orientativo" }}
        // helperText="Título orientativo"
      />
    );

    // const FileStep = (
    //   <div className="file-handler">
    //     { !!file && <p className="current-file">{file.name}</p>}
    //     <Button
    //       className="upload-file"
    //       onClick={() => {}}
    //       variant="contained"
    //       color="primary"
    //       component="label"
    //     >
    //       Adjuntar instancia
    //       <input
    //         type="file"
    //         hidden
    //         onChange={onFilesChange}
    //         ref={inputRef}
    //       />
    //     </Button>
    //   </div>
    // )
    
    if (extraordinary) {
      return [
        { id: 0, label: 'Alumno', component: StudentStep, nextCondition: userSelected },
        { id: 1, label: 'Titulación', component: TitulationStep, nextCondition: degreeId },
        { id: 2, label: 'Convocatoria', component: TermStep, nextCondition: termId && !!areas.length },
        { id: 3, label: 'Área temática', component: SubjectStep, nextCondition: areaId && academicYear },
        { id: 4, label: 'Director', component: InstructorStep, nextCondition: instructorId },
        { id: 5, label: 'Título', component: TitleStep, nextCondition: true },
        // { id: 5, label: 'Fichero de instancia', component: FileStep, nextCondition: file },
      ]
    }

    return [
      { id: 0, label: 'Titulación', component: TitulationStep, nextCondition: degreeId },
      { id: 1, label: 'Convocatoria', component: TermStep, nextCondition: termId && !!areas.length },
      { id: 2, label: 'Área temática', component: SubjectStep, nextCondition: areaId && academicYear },
      { id: 3, label: 'Director', component: InstructorStep, nextCondition: instructorId },
      { id: 4, label: 'Título', component: TitleStep, nextCondition: true },
      // { id: 4, label: 'Fichero de instancia', component: FileStep, nextCondition: file },
    ]
  }, [
    userSelected, control, titulations, terms, areas, instructors,
    extraordinary, degreeId, termId, areaId, academicYear, instructorId,
  ]);

  useEffect(() => {
    async function retrieveTitulations(studentId) {
      try {
        setApplicationLoading(true);
        // TODO: Add termId?
        const result = await getTFGTitulations(undefined, undefined, studentId).then(
          res => res.map(item => ({ value: item.externalId, label: item.name }))
        );
        if (!result) throw new Error();
        if (!result.length) {
          notification('error', 'No se han encontrado titulaciones con áreas temáticas disponibles');
        }
        setTitulations(result);
      } catch {
        notification('error', 'Ha habido un error al obtener las titulaciones');
      } finally {
        setApplicationLoading(false);
      }
    }

    if (!extraordinary) retrieveTitulations();
    else if (userSelected) retrieveTitulations(userSelected);
  }, [extraordinary, notification, userSelected]);

  useEffect(() => {
    async function retrieveTerms(id) {
      try {
        setApplicationLoading(true);
        const result = await getTFGTerms('AREA', id).then(
          res => res.map(item => ({ ...item, value: item.id, label: item.name }))
        );
        if (!result) throw new Error();
        setTerms(result);
      } catch {
        notification('error', 'Ha habido un error al obtener las convocatorias');
      } finally {
        setApplicationLoading(false);
      }
    }

    degreeId && retrieveTerms(degreeId);
  }, [notification, degreeId]);

  useEffect(() => {
    async function retrieveAreas(degreeId, academicYear) {
      try {
        setApplicationLoading(true);
        const result = await getTFGDegreeAreas(degreeId, academicYear).then(
          res => res.map(item => ({ ...item, value: item.id, label: item.name }))
        );
        if (!result) throw new Error();
        const mapped = result.filter(area => area.assignedManagers?.length);
        if (!mapped.length) {
          notification('error', 'No se han encontrado áreas temáticas disponibles para esta titulación, por favor escoge otra');
          return;
        }
        const sorted = mapped.sort((a, b) => a.name.localeCompare(b.name));
        setAreas(sorted);
      } catch {
        notification('error', 'Ha habido un error al obtener las áreas temáticas disponibles para la titulación seleccionada');
      } finally {
        setApplicationLoading(false);
      }
    }

    degreeId && academicYear && retrieveAreas(degreeId, academicYear);
  }, [notification, degreeId, academicYear]);

  useEffect(() => {
    async function retrieveCurrentRequests(degreeId, academicYear, userId) {
      try {
        setApplicationLoading(true);
        const result = await getTFGCurrentRequests(degreeId, academicYear, userId);
        if (!result) throw new Error();
        // const currentRequests = result.filter(req => req.status === 'PENDING');
        // if (currentRequests.length) {
        //   setPriority(currentRequests.length + 1);
        // }
        setPriority(result.length + 1);
      } catch {
        notification('error', 'Ha habido un error al obtener las solicitudes actuales');
        // Review how to handle if this service fails
        setPriority(1);
      } finally {
        setApplicationLoading(false);
      }
    }

    degreeId && academicYear && retrieveCurrentRequests(degreeId, academicYear, userSelected || undefined);
  }, [notification, degreeId, academicYear, userSelected])

  // Reset any possible selections
  useEffect(() => {
    setValue('degreeId', null);
  }, [setValue, userSelected]);

  useEffect(() => {
    setValue('termId', null);
  }, [setValue, degreeId]);

  useEffect(() => {
    setValue('areaId', null);
  }, [setValue, termId]);

  useEffect(() => {
    setValue('instructorId', null);
  }, [setValue, areaId]);

  const onSave = () => {
    confirm({
      title: 'Crear solicitud',
      description: 'Se va a proceder a crear una solicitud con la configuración seleccionada, ¿estás seguro de que deseas continuar?',
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          // If extraordinary/userSelected use different service
          const result = await createTFGRequest(degreeId, termId, areaId, instructorId, userSelected, provisionalTitle, priority);
          if (!result) throw new Error();
          // if (file) await uploadTFGFile(result.id, file);
          notification('success', 'La solicitud se ha creado correctamente');
          if (onSuccess) {
            onSuccess();
          } else {
            reset();
            setUserSelected('');
            setActiveStep(0);
          }
        } catch (err) {
          if (err?.response?.status === 409) {
            notification('error', 'Ya existe una solicitud abierta para esta titulación con el director seleccionado');
          } else notification('error', 'Ha habido un error al crear la solicitud');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const nextStep = () => {
    if (activeStep === steps.length - 1) {
      onSave();
      return;
    };
  
    setActiveStep(prev => prev + 1);
  }

  const previousStep = () => {
    setActiveStep(prev => {
      if (prev > 0) return prev - 1;
      return prev;
    })
  }

  const preferenceDisclaimerDescription = priority === 1
    ? 'Esta es tu primera solicitud para la convocatoria seleccionada y será considerada tu primera opción (Opción A).'
    : `Ya tienes otras solicitudes abiertas para la convocatoria seleccionada, esta será tu opción ${priorities[priority - 1]}.`;

  return (
    <div className="request-tfg">
      { priority && !!degreeId && !!termId && (
        <div className="preference-disclaimer__container">
          <Disclaimer
            type="info"
            className="preference-disclaimer"
            title="Preferencia de solicitud"
            description={preferenceDisclaimerDescription}
          />
        </div>
      ) }
      <Stepper
        className="stepper"
        activeStep={activeStep}
        alternativeLabel
      >
        { steps.map(step => (
          <Step key={`step-${step.id}`}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="stepper-body">
        { steps.find(step => activeStep === step.id).component }
      </div>
      <div className="stepper-actions">
        <Button
          onClick={previousStep}
          disabled={activeStep === 0}
          variant="contained"
          color="secondary"
        >
          Atrás
        </Button>
        <Button
          onClick={nextStep}
          variant="contained"
          color="primary"
          disabled={!steps[activeStep].nextCondition}
        >
          { activeStep === steps.length - 1 ? 'Guardar' : 'Continuar' }
        </Button>
      </div>
    </div>
  )
}

export default TFGRequest