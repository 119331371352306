import React, { useState } from 'react'
import { Button } from '@material-ui/core';
import TFGRequestsTable from '../TFGRequestsTable';
import TFGRequest from '../../TFGRequest'
import './TFGRequestsContainer.scss'

const TFGRequestContainer = ({ onRequestsChange, student }) => {
  const [isRequest, setIsRequest] = useState(false)

  const toggleView = () => {
    setIsRequest(value => !value)
  }

  return !isRequest ?
      <>
        <div className="defense-request__actions">
          <Button onClick={toggleView} variant="contained" color="primary">Crear solicitud de área temática</Button>
        </div>
        <TFGRequestsTable student={student} onRequestsChange={onRequestsChange}/>
      </>
    : <TFGRequest onSuccess={toggleView}/>
}

export default TFGRequestContainer;