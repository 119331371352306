import { isDev } from "../config";

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
export const validIban = (value) => {
    let rearrange =
        value.substring(4, value.length)
        + value.substring(0, 4);
    let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
    let alphaMap = {};
    let number = [];

    alphabet.forEach((value, index) => {
        alphaMap[value] = index + 10;
    });

    rearrange.split('').forEach((value, index) => {
        number[index] = alphaMap[value] || value;
    });

    return modulo(number.join('').toString(), 97) === 1;
}

const modulo = (aNumStr, aDiv) => {
    let tmp = "";
    let i, r;
    for (i = 0; i < aNumStr.length; i++) {
        tmp += aNumStr.charAt(i);
        r = tmp % aDiv;
        tmp = r.toString();
    }
    return tmp / 1;
}

export const sleep = async ms =>
  await new Promise((res, _rej) => setTimeout(res, ms));

/**
 * Auxiliar function to catch request errors in development, returning mocked data
 * @param err - The err thrown when performing the HTTP request
 * @param devReturn - The data to be returned when the current environment is development
 */
export const localDevCatch = async (err, devReturn) => {
  if (isDev) {
    await sleep(750);
    console.log('[DEV] Mocked result returned', devReturn);
    return devReturn;
  }
  throw err;
}

/**
 * Copy the provided text to the user clipboard
 * TODO: Update to Clipboard API
 * @param str String to be copied
 */
 export function copyToClipboard(str) {
  return new Promise((resolve, reject) => {
    try {
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.className = 'clipboard-hidden-element';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      resolve();
    } catch {
      reject();
    }
  });
}

/**
 * Generates a blob object from raw data, then automatically triggers user's browser download
 * @param rawData - Data to be converted to blob then downloaded
 */
 export const downloadFromRawData = (rawData, fileName) => {
  // Forcing the mimetype is a workaround for Firefox bug #1756980, it will be removed when target issue is fixed
  const url = window.URL.createObjectURL(new Blob([rawData], { type: 'application/octet-stream' }));
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  // link.target = "_blank";
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 2500);
}

/** Generate a FormData body with the provided file */
export const generateFileFormData = (file, attribute = 'file') => {
  const formData = new FormData();
  formData.append(attribute, file);
  return formData;
}

export const required = {
  required: 'Este campo es obligatorio',
}

/** Cleans the provided object empty properties */
export const cleanUpObject = (values) => Object.keys(values).forEach((key) => {
  if(!values[key]) delete values[key];
})

/**
 * Generates a random ID between 50 and 500, used for local API mock responses.
 * @returns The generated random ID.
 */
export const generateRandomId = () => Math.floor(Math.random() * (500 - 50) + 50);