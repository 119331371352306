import React, { useState, useEffect, useMemo } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, Tooltip } from '@material-ui/core'
import InputText from '../../../../components/Inputs/InputText';
import { useNotification } from '../../../../Utils/hooks';
import { getTFGTitulations } from '../../../../services/TFG';
import InputAutoComplete from '../../../../components/Inputs/InputAutoComplete';

const InstructorAllocationDialog = ({ selectedCoord, selectedTerm, visible, onHide, onSave }) => {
  const [generalLimit, setGeneralLimit] = useState('');
  const [degreeLimits, setDegreeLimits] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const notification = useNotification();

  const getSelectableDegrees = (degrees) => {
    const selectedDegrees = degreeLimits.map(d => (d.degree?.split(',') ?? [])).flat();
    return degrees.filter(d => !selectedDegrees.includes(d.value))
  }

  const selectableDegrees = useMemo(() => getSelectableDegrees(degrees, degreeLimits), [degreeLimits])

  useEffect(() => {
    if (!selectedCoord) {
      setGeneralLimit('');
      setDegreeLimits([]);
    } else {
      const allocation = selectedCoord.yearlyAllocations.find(item => item.academicYearId === selectedTerm);
      if (!allocation) return;
      setGeneralLimit(allocation.yearlyLimit);
      const degrees = allocation.degreeAllocations.map(item => ({degree: item.degreeIds.toString(), limit: item.limit }))
      setDegreeLimits(degrees);
    }
  }, [selectedCoord, selectedTerm])

  useEffect(() => {
    async function retrieveTitulations(selectedTerm) {
      try {
        const result = await getTFGTitulations(selectedTerm, true).then(
          res => res.map(item => ({ value: item.externalId, label: item.name }))
        );
        if (!result) throw new Error();
        if (!result.length) {
          notification('error', 'No se han encontrado titulaciones con áreas temáticas disponibles');
        }
        setDegrees(result);
      } catch {
        notification('error', 'Ha habido un error al obtener las titulaciones');
      }
    }

    if (selectedTerm) retrieveTitulations(selectedTerm);
  }, [notification, selectedTerm]);

  const onAddDegreeLimit = (degree, limit) => {
    setDegreeLimits(prev => [ ...prev, { degree, limit } ]);
  }

  const onRemoveDegreeLimit = (degree) => {
    setDegreeLimits(prev => prev.filter(item => item.degree !== degree));
  }

  const onEditDegreeLimit = (index, degree, limit) => {
    console.log('onEdit', [...degreeLimits], index, degree, limit)
    setDegreeLimits(prev => prev.map((item, i) => i === index ? { degree: degree.toString(), limit: limit } : item));
  }

  const onSaveDialog = () => {
    if (generalLimit === "" || degreeLimits.some(item => !item.degree || !item.limit)) {
      notification('error', 'Por favor, rellene todos los campos');
      return;
    }

    // If sum of degree limits is greater than general limit, return
    // const sum = degreeLimits.reduce((acc, item) => acc + +item.limit, 0);
    // if (sum > +generalLimit) {
    //   notification('error', 'La suma de los límites por titulación no puede ser mayor que el límite general');
    //   return;
    // }

    onSave(generalLimit, degreeLimits);
  }

  return (
    <Dialog
      className="instructor-allocation-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Modificar asignación de {selectedCoord?.instructorName}</DialogTitle>
      <DialogContent>
        <div className="general-limit">
          <InputText
            name="generalLimit"
            value={generalLimit}
            onChange={e => setGeneralLimit(e.target.value)}
            label="Límite general de TFGs para el director"
            disabled
            // placeholder="Lugar donde se realizará la defensa"
          />
        </div>
        <div className="degree-limits">
          <div className="header">
            <h4>Límites por titulación</h4>
            <Tooltip title="Añadir" aria-label="add">
              <IconButton disabled={!selectableDegrees.length} aria-label="add" onClick={() => onAddDegreeLimit(null, '')}>
                <Icon className="add-icon">
                  add
                </Icon>
              </IconButton>
            </Tooltip>
          </div>
          <Grid container spacing={2}>
            { !degreeLimits.length && (
              <p className="empty-list">No hay límites configurados</p>
            ) }
            { degreeLimits.map((item, index) => (
              <>
                <Grid item xs={12} md={5}>
                  <InputAutoComplete
                    key={`degree-${index}`}
                    name={`degree-${index}`}
                    multiple={true}
                    value={item.degree?.split(',')}
                    onChange={value => onEditDegreeLimit(index, value, item.limit)}
                    label="Titulación"
                    options={degrees}
                    disabled={!degrees.length}
                    filterOptions={getSelectableDegrees}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <InputText
                    key={`limit-${index}`}
                    name={`limit-${index}`}
                    value={item.limit}
                    onChange={e => onEditDegreeLimit(index, item.degree, e.target.value)}
                    label="Límite"
                    inputProps={{ type: 'number', min: 0 }}
                    // placeholder="Lugar donde se realizará la defensa"
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Tooltip title="Eliminar" aria-label="delete">
                    <IconButton aria-label="delete" onClick={() => onRemoveDegreeLimit(item.degree)}>
                      <Icon className="delete-icon">
                        delete
                      </Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </>
            )) }
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={onSaveDialog} >
          Guardar cambios
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InstructorAllocationDialog